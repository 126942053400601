import path from 'path';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { homepageUrl, workspacesUrl } from '@/config';

path.resolve('./next.config.js');

const Page = () => {
  const { engagementScore } = useFlags();
  const router = useRouter();

  useEffect(() => {
    void router.push(engagementScore ? homepageUrl() : workspacesUrl());
  }, [router, engagementScore]);

  return <></>;
};

export default Page;
